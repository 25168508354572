/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

/* Functions to get around Angular Material not letting you set foreground and background natively */
@function define-light-theme($config, $light-theme-background-palette, $light-theme-foreground-palette) {
  $theme: mat.define-light-theme($config);
  $color: map-get($theme, color);
  $color: map-merge(
    $color,
    (
      background: $light-theme-background-palette,
      foreground: $light-theme-foreground-palette,
    )
  );
  @return map-merge(
    $theme,
    (
      color: $color,
    )
  );
}

@function define-dark-theme($config, $dark-theme-background-palette, $dark-theme-foreground-palette) {
  $theme: mat.define-dark-theme($config);
  $color: map-get($theme, color);
  $color: map-merge(
    $color,
    (
      background: $dark-theme-background-palette,
      foreground: $dark-theme-foreground-palette,
    )
  );
  @return map-merge(
    $theme,
    (
      color: $color,
    )
  );
}

@include mat.core();

// Theme Config
$gruvbox: (
  orange: #d65d0e,
  orange-light: #fe8019,
  orange-dark: #af3a03,
  red: #cc241d,
  red-light: #fb4934,
  red-dark: #9d0006,
  green: #98971a,
  green-light: #b8bb26,
  green-dark: #79740e,
  yellow: #d79921,
  yellow-light: #fabd2f,
  yellow-dark: #b57614,
  blue: #458588,
  blue-light: #83a598,
  blue-dark: #076678,
  purple: #b16286,
  purple-light: #d3869b,
  purple-dark: #8f3f71,
  aqua: #689d6a,
  aqua-light: #8ec07c,
  aqua-dark: #427b58,
  black: #282828,
  black-light: #32302f,
  black-dark: #1d2021,
);

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2Q2NWQwZSIsIj9lcjwjZmU4MDE5IiwiO2VyPCNjNjQxMDh$LCIlPmBePCM0NTg1ODgiLCI~ZXI8IzgzYTU5OCIsIjtlcjwjNjY4YTdifiwid2Fybj5gXjwjY2MyNDFkIiwiP2VyPCNmYjQ5MzQiLCI7ZXI8I2I5MTYxMX4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZiZjFjNyIsIjs9PCMyODI4Mjh$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PElCTSBQbGV4IE1vbm8iLCJ2YXJpYW50PG1lZGl1bX4sYEA8KC0zIiwiZmFtaWx5PElCTSBQbGV4IFNhbnMiLCJ2YXJpYW50PG1lZGl1bX4sYEA8KC0yIiwiZmFtaWx5PElCTSBQbGV4IFNhbnMgQ29uZGVuc2VkIiwidmFyaWFudDxtZWRpdW1$LGBAPCgtMSIsImZhbWlseTxJQk0gUGxleCBNb25vfixgQDxoZWFkbGluZSIsImZhbWlseTxJQk0gUGxleCBNb25vIiwidmFyaWFudDxtZWRpdW1$LGBAPHRpdGxlIiwiZmFtaWx5PElCTSBQbGV4IE1vbm8iLCJ2YXJpYW50PHJlZ3VsYXJ$LGBAPHN1YiktMiIsImZhbWlseTxJQk0gUGxleCBNb25vfixgQDxzdWIpLTEiLCJmYW1pbHk8SUJNIFBsZXggU2FucyIsInZhcmlhbnQ8P34sYEA8Ym9keS0yIiwiZmFtaWx5PElCTSBQbGV4IFNhbnN$LGBAPGJvZHktMSIsImZhbWlseTxJQk0gUGxleCBTYW5zfixgQDxidXR0b24iLCJmYW1pbHk8SUJNIFBsZXggTW9ubyIsInZhcmlhbnQ8PyIsInNwYWNpbmc$MTI1fSxgQDxjYXB0aW9uIiwiZmFtaWx5PElCTSBQbGV4IE1vbm9$LGBAPGlucHV0IiwiZmFtaWx5PElCTSBQbGV4IE1vbm8iLCJzaXplPm51bGx9XSwiaWNvbnM8RmlsbGVkIiwiP25lc3M$ZmFsc2UsInZlcnNpb24$MTN9
*/

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@300;400;500&display=swap');

$font-config: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 500, 'IBM Plex Mono'),
  $headline-2: mat.define-typography-level(56px, 56px, 500, 'IBM Plex Sans'),
  $headline-3: mat.define-typography-level(45px, 48px, 500, 'IBM Plex Sans Condensed'),
  $headline-4: mat.define-typography-level(34px, 40px, 400, 'IBM Plex Mono'),
  $headline-5: mat.define-typography-level(24px, 32px, 500, 'IBM Plex Mono'),
  $headline-6: mat.define-typography-level(24px, 32px, 300, 'IBM Plex Mono'),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400, 'IBM Plex Mono'),
  $subtitle-2: mat.define-typography-level(15px, 24px, 300, 'IBM Plex Sans'),
  $body-2: mat.define-typography-level(14px, 24px, 500, 'IBM Plex Sans'),
  $body-1: mat.define-typography-level(14px, 20px, 400, 'IBM Plex Mono'),
  $button: mat.define-typography-level(14px, 14px, 500, 'IBM Plex Mono'),
  $caption: mat.define-typography-level(12px, 20px, 400, 'IBM Plex Mono'),
);

// Foreground Elements

// Light Theme Text
$dark-text: map-get($gruvbox, black);
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #fbf1c7;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fffce2;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#282828, 20%);
$dark-bg-alpha-4: rgba(#282828, 0.04);
$dark-bg-alpha-12: rgba(#282828, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #282828;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

:root {
  --gruvbox-orange: #{map-get($gruvbox, orange)};
  --gruvbox-orange-light: #{map-get($gruvbox, orange-light)};
  --gruvbox-orange-dark: #{map-get($gruvbox, orange-dark)};
  --gruvbox-red: #{map-get($gruvbox, red)};
  --gruvbox-red-light: #{map-get($gruvbox, red-light)};
  --gruvbox-red-dark: #{map-get($gruvbox, red-dark)};
  --gruvbox-green: #{map-get($gruvbox, green)};
  --gruvbox-green-light: #{map-get($gruvbox, green-light)};
  --gruvbox-green-dark: #{map-get($gruvbox, green-dark)};
  --gruvbox-yellow: #{map-get($gruvbox, yellow)};
  --gruvbox-yellow-light: #{map-get($gruvbox, yellow-light)};
  --gruvbox-yellow-dark: #{map-get($gruvbox, yellow-dark)};
  --gruvbox-blue: #{map-get($gruvbox, blue)};
  --gruvbox-blue-light: #{map-get($gruvbox, blue-light)};
  --gruvbox-blue-dark: #{map-get($gruvbox, blue-dark)};
  --gruvbox-purple: #{map-get($gruvbox, purple)};
  --gruvbox-purple-light: #{map-get($gruvbox, purple-light)};
  --gruvbox-purple-dark: #{map-get($gruvbox, purple-dark)};
  --gruvbox-aqua: #{map-get($gruvbox, aqua)};
  --gruvbox-aqua-light: #{map-get($gruvbox, aqua-light)};
  --gruvbox-aqua-dark: #{map-get($gruvbox, aqua-dark)};
  --gruvbox-black: #{map-get($gruvbox, black)};
  --gruvbox-black-light: #{map-get($gruvbox, black-light)};
  --gruvbox-black-dark: #{map-get($gruvbox, black-dark)};
}

$mat-primary: (
  main: map-get($gruvbox, orange),
  lighter: map-get($gruvbox, orange-light),
  darker: map-get($gruvbox, orange-dark),
  200: map-get($gruvbox, orange),
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --primary-color: #{map-get($mat-primary, main)};
  --primary-lighter-color: #{map-get($mat-primary, lighter)};
  --primary-darker-color: #{map-get($mat-primary, darker)};
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: map-get($gruvbox, blue),
  lighter: map-get($gruvbox, blue-light),
  darker: map-get($gruvbox, blue-dark),
  200: map-get($gruvbox, blue),
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --accent-color: #{map-get($gruvbox, blue)};
  --accent-lighter-color: #{map-get($gruvbox, blue-light)};
  --accent-darker-color: #{map-get($gruvbox, blue-dark)};
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: map-get($gruvbox, red-light),
  lighter: map-get($gruvbox, red-light),
  darker: map-get($gruvbox, red),
  200: map-get($gruvbox, red-light),
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $light-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

body {
  --warn-color: #{map-get($gruvbox, red)};
  --warn-lighter-color: #{map-get($gruvbox, red-light)};
  --warn-darker-color: #{map-get($gruvbox, red-dark)};
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$light-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$theme: define-dark-theme(
  mat.define-dark-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn,
      ),
    )
  ),
  $mat-dark-theme-background,
  $mat-dark-theme-foreground
);

$altTheme: define-light-theme(
  mat.define-light-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn,
      ),
    )
  ),
  $mat-light-theme-background,
  $mat-light-theme-foreground
);

// Theme Init
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($font-config);
@include mat.core-typography($font-config);

.theme-alternate {
  @include mat.all-component-colors($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

//noinspection CssNoGenericFontName
.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'IBM Plex Mono';
  }
}

@import '@aws-amplify/ui-angular/theme.css';

:root,
[data-amplify-theme] {
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
:root {
  --amplify-colors-brand-primary-10: #{map-get($mat-primary, lighter)};
  --amplify-colors-brand-primary-20: #{map-get($mat-primary, lighter)};
  --amplify-colors-brand-primary-40: #{map-get($mat-primary, main)};
  --amplify-colors-brand-primary-60: #{map-get($mat-primary, main)};
  --amplify-colors-brand-primary-80: #{map-get($mat-primary, main)};
  --amplify-colors-brand-primary-90: #{map-get($mat-primary, darker)};
  --amplify-colors-brand-primary-100: #{map-get($mat-primary, darker)};

  --amplify-colors-neutral-10: #{map-get($mat-dark-theme-background, status-bar)};
  --amplify-colors-neutral-20: #{map-get($mat-dark-theme-background, status-bar)};
  --amplify-colors-neutral-40: #{map-get($mat-dark-theme-background, selected-disabled-button)};

  --amplify-colors-font-active: var(--amplify-colors-brand-primary-100);
  --amplify-colors-font-disabled: #{map-get($mat-dark-theme-background, disabled-button)};
  --amplify-colors-font-error: #{map-get($mat-warn, main)};
  --amplify-colors-font-focus: var(--amplify-colors-brand-primary-100);
  --amplify-colors-font-hover: var(--amplify-colors-brand-primary-90);
  --amplify-colors-font-info: var(--amplify-colors-brand-secondary-90);
  --amplify-colors-font-interactive: var(--amplify-colors-brand-primary-80);
  --amplify-colors-font-inverse: #{$dark-text};
  --amplify-colors-font-primary: #{$light-text};
  --amplify-colors-font-secondary: #{$light-accent-text};
  --amplify-colors-font-success: var(--amplify-colors-green-90);
  --amplify-colors-font-tertiary: #{$light-accent-text};
  --amplify-colors-font-warning: var(--amplify-colors-brand-primary-10);

  --amplify-colors-neutral-90: hsl(210, 5%, 94%);
  //--amplify-colors-neutral-100: hsl(210, 5%, 98%);

  --amplify-colors-white: #{map-get($mat-dark-theme-background, app-bar)};

  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-overlay-10: hsla(0, 0%, 100%, 0.1);
  --amplify-colors-overlay-20: hsla(0, 0%, 100%, 0.2);
  --amplify-colors-overlay-30: hsla(0, 0%, 100%, 0.3);
  --amplify-colors-overlay-40: hsla(0, 0%, 100%, 0.4);
  --amplify-colors-overlay-50: hsla(0, 0%, 100%, 0.5);
  --amplify-colors-overlay-60: hsla(0, 0%, 100%, 0.6);
  --amplify-colors-overlay-70: hsla(0, 0%, 100%, 0.7);
  --amplify-colors-overlay-80: hsla(0, 0%, 100%, 0.8);
  --amplify-colors-overlay-90: hsla(0, 0%, 100%, 0.9);

  --amplify-fonts-default-variable: 'IBM Plex Sans';
}

.themed-overlay-color {
  color: $light-text;
}

.mdc-button__label {
  color: var(--text-primary-color)
}
